'use client';

import { useTranslations } from 'next-intl';
import { useRequest } from 'ahooks';
import { useEffect, useState } from 'react';
import { getProductPublicWebPopularProductList } from '@/lib/services/zhuye';
import { Goods, NewLoading } from '@/components/commons';
import storage from '@/shared/utils/storage';
import { THEME_DARK_MODE } from '@/shared/constants';
import { useRouter } from 'next/router';

const Popular = ({
  inCartPage = false,
  inSearchPage = false,
  handleCollection,
  collectData,
  selectedGoods,
}: {
  inCartPage?: boolean;
  inSearchPage?: boolean;
  handleCollection?: (value: boolean, item: API.WebProductItemVo) => void;
  collectData?: any[];
  selectedGoods?: any;
}) => {
  const t = useTranslations('Home');
  const { pathname } = useRouter();
  const [pageSize, setPageSize] = useState<number>(0);
  const [pageNo, setPageNo] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [renderData, setRenderData] = useState<any[]>([]);
  // 热门商品列表
  const { loading } = useRequest(
    () =>
      getProductPublicWebPopularProductList({
        pageNo: pageNo,
        pageSize: inCartPage ? 18 : pageSize,
      }),
    {
      manual: !(pageSize > 0),
      cacheKey: 'home-popular',
      refreshDeps: [pageNo, pageSize],
      onSuccess: (response) => {
        const oldData = [...renderData];
        const newData = oldData.concat(response?.data?.list || []);
        if (inCartPage) {
          setRenderData((response?.data?.list || []).slice(0, 18));
        } else {
          setRenderData(newData);
        }
        setTotal(Number(response?.data?.total) || 0);
      },
    },
  );

  const [darkMode, setDarkMode] = useState<boolean>(false);

  useEffect(() => {
    setDarkMode(storage.get(THEME_DARK_MODE) || false);
  }, []);

  // 每行显示的个数
  const [rowQuantity, setRowQuantity] = useState<number>(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  // 120px是左右边距，固定布局
  useEffect(() => {
    const newRowQuantity = Math.floor((width - 120 - 120) / (180 + 12 + 12));
    setRowQuantity(newRowQuantity);
    setPageSize(newRowQuantity);
  }, [width]);

  return (
    <section className={`box-border w-full py-6 ${renderData && !!renderData.length ? 'block' : 'hidden'}`}>
      <div className={`${inCartPage ? '' : 'container'}`}>
        <h2 className={`mb-6 text-lg font-semibold leading-6 ${darkMode ? 'text-white' : 'text-black'}`}>
          {t('popular')}
        </h2>
        <div
          className={`grid justify-between gap-6`}
          style={{ gridTemplateColumns: `repeat(${rowQuantity}, minmax(0, 1fr))` }}
        >
          {(renderData || [])
            .map((goodsItem) => {
              return {
                ...goodsItem,
                favorited:
                  pathname === '/favorites'
                    ? !!(collectData || []).find((x) => x.productId === goodsItem.productId)
                    : goodsItem.favorited,
              };
            })
            .map((x) => {
              return (
                <div key={x.productId} className="w-[180px]">
                  <Goods
                    item={{
                      ...x,
                      favorited: x.productId === selectedGoods?.productId ? selectedGoods?.favorited : x.favorited,
                    }}
                    handleCollection={handleCollection}
                  />
                </div>
              );
            })}
        </div>
        <div className="mt-6 flex w-full flex-row items-center justify-center">
          {loading ? (
            <NewLoading />
          ) : total > pageSize * pageNo && !inCartPage && !inSearchPage ? (
            <div
              onClick={() => {
                if (loading) {
                  return;
                }
                setPageNo(pageNo + 1);
              }}
              className={`flex h-12 w-[384px] cursor-pointer flex-row items-center justify-center rounded-xl text-base font-medium ${darkMode ? 'bg-[#3a969b3d] text-[#3A969B]' : 'bg-[#4dbac03d] text-primary'}`}
            >
              {t('showMore')}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </section>
  );
};

export default Popular;

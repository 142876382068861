'use client';

import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import { getProductPublicWebRecommendProductList } from '@/lib/services/zhuye';
import { Goods, NewLoading } from '@/components/commons';
import storage from '@/shared/utils/storage';
import { THEME_DARK_MODE } from '@/shared/constants';
import { useRouter } from 'next/router';

const RecommendForH5 = ({
  noMoreButton = false,
  handleCollection,
  collectData,
  selectedGoods,
}: {
  noMoreButton?: boolean;
  handleCollection?: (value: boolean, item: API.WebProductItemVo) => void;
  collectData?: any[];
  selectedGoods?: any;
}) => {
  const { pathname } = useRouter();
  const t = useTranslations('Home');
  const [pageNo, setPageNo] = useState<number>(1);
  const pageSize = 6;
  const [total, setTotal] = useState<number>(0);
  const [renderData, setRenderData] = useState<any[]>([]);
  // 推荐商品列表
  const { loading } = useRequest(
    () =>
      getProductPublicWebRecommendProductList({
        pageNo: pageNo,
        pageSize: pageSize,
      }),
    {
      manual: false,
      cacheKey: 'home-recommend-h5',
      refreshDeps: [pageNo],
      onSuccess: (response) => {
        const oldData = [...renderData];
        const newData = oldData.concat(response?.data?.list || []);
        setRenderData(newData);
        setTotal(Number(response?.data?.total) || 0);
      },
    },
  );

  const [darkMode, setDarkMode] = useState<boolean>(false);

  useEffect(() => {
    setDarkMode(storage.get(THEME_DARK_MODE) || false);
  }, []);

  return noMoreButton ? (
    <div className={`flex w-full flex-row items-start justify-between overflow-x-auto`}>
      {(renderData || [])
        .map((goodsItem) => {
          return {
            ...goodsItem,
            favorited:
              pathname === '/favorites'
                ? !!(collectData || []).find((x) => x.productId === goodsItem.productId)
                : goodsItem.favorited,
          };
        })
        .map((x) => {
          return (
            <div key={x.productId} className="mr-2 w-36 last:mr-0">
              <Goods
                item={{
                  ...x,
                  favorited: x.productId === selectedGoods?.productId ? selectedGoods?.favorited : x.favorited,
                }}
                width={144}
                noAnimation
                handleCollection={handleCollection}
              />
            </div>
          );
        })}
    </div>
  ) : (
    <section className={'box-border w-full py-3'}>
      <h2 className={`mb-3 text-base font-semibold ${darkMode ? 'text-white' : 'text-black'}`}>{t('recommend')}</h2>
      <div className={`grid grid-cols-2 justify-between gap-4`}>
        {(renderData || [])
          .map((goodsItem) => {
            return {
              ...goodsItem,
              favorited:
                pathname === '/favorites'
                  ? !!(collectData || []).find((x) => x.productId === goodsItem.productId)
                  : goodsItem.favorited,
            };
          })
          .map((goodsItemData) => {
            return (
              <div key={goodsItemData.productId} className="mb-2">
                <Goods
                  item={{
                    ...goodsItemData,
                    favorited:
                      goodsItemData.productId === selectedGoods?.productId
                        ? selectedGoods?.favorited
                        : goodsItemData.favorited,
                  }}
                  noAnimation={noMoreButton}
                  handleCollection={handleCollection}
                />
              </div>
            );
          })}
      </div>
      <div className="mt-3 flex h-12 w-full flex-row items-center justify-center">
        {loading ? (
          <NewLoading />
        ) : total > pageSize * pageNo ? (
          <div
            onClick={() => {
              if (loading) {
                return;
              }
              setPageNo(pageNo + 1);
            }}
            className={`flex h-12 w-full flex-row items-center justify-center rounded-xl text-base font-medium ${darkMode ? 'bg-[#3a969b3d] text-[#3A969B]' : 'bg-[#4dbac03d] text-primary'}`}
          >
            {t('showMore')}
          </div>
        ) : (
          ''
        )}
      </div>
    </section>
  );
};

export default RecommendForH5;
